<template>
    <main>
        <b-tabs>
            <b-tab title="Informasi Pembeli">
                <template>
                    <b-form>
                        <b-row>
                            <b-col md="12">
                                <b-card>
                                    <b-row>
                                        <b-col md="4">
                                            <!-- <b-card> -->
                                            <b-row>
                                                <b-col md="12">
                                                    <b-form-group label="Date">
                                                        <b-form-input v-model="date" type="date"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-form-group label="Kasir">
                                                        <b-form-input v-model="cashier"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-form-group label="Customer">
                                                        <b-form-select v-model="customer"
                                                            :options="customers"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <!-- </b-card> -->
                                        </b-col>
                                        <!-- </b-row>

                        <b-row> -->
                                        <b-col md="4">
                                            <!-- <b-card> -->
                                            <b-row>
                                                <b-col md="12">
                                                    <b-form-group label="Barcode">
                                                        <b-form-input v-model="barcode"
                                                            @keydown.enter.prevent="addItem()"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col>
                                                    <b-alert :show="dismissCountDown" dismissible variant="danger"
                                                        @dismissed="dismissCountDown = 0"
                                                        @dismiss-count-down="countDownChanged">
                                                        <div class="alert-body">
                                                            <span>{{ $t('Product not found') }}</span>
                                                        </div>
                                                    </b-alert>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-form-group label="Qty">
                                                        <b-form-input v-model="qty" type="number"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12" class="d-flex align-items-end">
                                                    <b-button @click="addItem" variant="primary">Add</b-button>
                                                </b-col>

                                            </b-row>
                                            <!-- </b-card> -->
                                        </b-col>
                                        <b-col md="4">
                                            <!-- <b-card> -->
                                            <b-row>
                                                <b-col md="12">
                                                    <h3>Invoice</h3>
                                                </b-col>
                                                <b-col md="12">
                                                    <h1>{{ grandTotal }}</h1>
                                                </b-col>
                                            </b-row>
                                            <!-- </b-card> -->
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>

                        <!-- Items Table -->
                        <b-row>
                            <b-col md="12">
                                <b-card>
                                    <b-table striped hover :items="items" :fields="fields">
                                        <template #cell(product)="{ item }">
                                            <!-- <span v-if="item"> -->
                                            {{ item.product_varian }}<br>
                                            <small>{{ item.product_kategori }}</small> /
                                            <small>{{ item.product }}</small>
                                            <!-- </span>
                                            <i v-else class="text-danger">Barang tidak ada.</i> -->
                                        </template>
                                        <template #cell(actions)="data">
                                            <b-button @click="updateItem(data.index)" size="sm"
                                                variant="info">Update</b-button>
                                            <b-button @click="deleteItem(data.index)" size="sm"
                                                variant="danger">Delete</b-button>
                                        </template>
                                    </b-table>
                                </b-card>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4">
                                <b-card>
                                    <b-row class="">
                                        <b-col md="12">
                                            <b-form-group label="Sub Total">
                                                <b-form-input :value="subTotal" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-form-group label="Discount">
                                                <b-form-input v-model="discount"
                                                    @input="calculateTotals()"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-form-group label="Grand Total">
                                                <b-form-input :value="grandTotal" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                            <!-- </b-row>
                        <b-row> -->
                            <b-col md="4">
                                <b-card>
                                    <b-row>
                                        <b-col md="12">
                                            <b-form-group label="Cash">
                                                <b-form-input v-model="cash" @input="kembalian()"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-form-group label="Change">
                                                <b-form-input v-model="change" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-button @click="processPayment" variant="success">Process
                                                Payment</b-button>
                                            <b-button @click="cancel" variant="warning">Cancel</b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                            <b-col md="4">
                                <b-card>
                                    <b-row>
                                        <b-col md="12">
                                            <b-form-group label="Note">
                                                <b-form-textarea v-model="cash"></b-form-textarea>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                </b-card>
                            </b-col>
                        </b-row>

                    </b-form>
                </template>
            </b-tab>
            <b-tab title="Informasi Pembeli">
                <b-overlay :show="loadingInfoBarang">
                    <b-card-actions :no-actions="true">
                        <b-row class="mb-2">
                            <b-col md="12" sm="12">
                                <b-alert variant="primary" show>
                                    <h2 class="alert-heading">
                                        Informasi Pembeli
                                    </h2>
                                </b-alert>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group label="Nama Pembeli" label-for="">
                                    <b-form-input v-model="markers[0].nama_pembeli" type="text"
                                        placeholder="Ex: Sarung Tangan Medis" />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group label="Telp Pembeli" label-for="">
                                    <b-form-input type="number" v-model="markers[0].telp_pembeli"
                                        placeholder="Ex: 08222222222" />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group label="E-mail Pembeli" label-for="">
                                    <b-form-input type="email" v-model="markers[0].email_pembeli"
                                        placeholder="official@heykama.com" />
                                </b-form-group>
                            </b-col>
                            <b-col md="12" sm="12">
                                <b-form-group label="Catatan Pembeli">
                                    <b-form-textarea v-model="markers[0].catatan_pembeli"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-actions>
                </b-overlay>
            </b-tab>
            <b-tab title="Informasi Penerima">
                <b-overlay :show="loadingInfoBarang">
                    <b-card>
                        <b-row class="mb-2">
                            <b-col md="12" sm="12">
                                <b-alert variant="primary" show>
                                    <h2 class="alert-heading">
                                        Informasi Penerima
                                    </h2>
                                </b-alert>
                            </b-col>
                            <b-col md="12" sm="12">
                                <div>
                                    <b-row>
                                        <b-col md="6" sm="12">
                                            <b-form>
                                                <b-row>
                                                    <b-col md="6" sm="12">
                                                        <b-form-group>
                                                            <label for="nama_kontak">Nama Lengkap</label>
                                                            <b-form-input v-model="markers[0].nama_kontak"
                                                                id="nama_kontak" type="text"
                                                                placeholder="Nama Lengkap" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6" sm="12">
                                                        <b-form-group>
                                                            <label for="nomor_kontak">No. Telepon (Aktif)</label>
                                                            <b-form-input v-model="markers[0].nomor_kontak"
                                                                id="nomor_kontak" type="text"
                                                                placeholder="Nomor Telepon" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6" sm="12">
                                                        <b-form-group label="Provinsi" label-for="v-provinsi">
                                                            <v-select v-model="markers[0].provinsi_id"
                                                                :reduce="(option) => option.value" label="text"
                                                                :options="optprovinsi" @input="getkabkota()" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6" sm="12">
                                                        <b-form-group label="Kabupaten/Kota" label-for="v-kabko">
                                                            <v-select v-model="markers[0].kab_kota_id"
                                                                :reduce="(option) => option.value" label="text"
                                                                :options="optkabko" @input="getkecamatan()" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6" sm="12">
                                                        <b-form-group label="Kecamatan" label-for="v-kecamatan">
                                                            <v-select v-model="markers[0].kecamatan_id"
                                                                :reduce="(option) => option.value" label="text"
                                                                :options="optkec" @input="getkelurahan()" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6" sm="12">
                                                        <b-form-group label="Kelurahan/Desa" label-for="v-kel">
                                                            <v-select v-model="markers[0].desa_id"
                                                                :reduce="(option) => option.value" label="text"
                                                                :options="optkel" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="12" sm="12">
                                                        <b-form-group>
                                                            <label for="alamat">Alamat</label>
                                                            <b-form-textarea v-model="markers[0].alamat" id="alamat"
                                                                placeholder="Nama Jalan, Gang, Gedung, No Rumah" />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="12" sm="12">
                                                        <b-form-group>
                                                            <label for="reward">Catatan</label>
                                                            <b-form-textarea v-model="markers[0].catatan" id="deskripsi"
                                                                placeholder="Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)" />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-form>
                                            <!-- <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                                                    Simpan </b-button> -->
                                        </b-col>
                                        <!-- <p id="demo" v-text="text"></p> -->
                                        <!-- <b-col md="6" sm="12">
                                                                <l-popup :content="marker.tooltip" />
                                                                <l-tooltip :content="marker.tooltip" />
                                                            </l-marker>
                                                        </l-map>
                                                    </b-overlay>
                                                </b-col>
                                            </b-row>

                                        </b-col> -->
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-overlay>

            </b-tab>
            <b-tab title="Informasi Produk">
                <b-overlay :show="loadingInfoBarang">
                    <b-card-actions :no-actions="true">
                        <b-row class="mb-2">
                            <b-col md="12" sm="12">
                                <b-alert variant="primary" show>
                                    <h2 class="alert-heading">
                                        Informasi Produk
                                    </h2>
                                </b-alert>
                            </b-col>
                            <b-col md="8" sm="12" class="">
                                <b-form-group label-size="sm">
                                    <b-input-group>
                                        <b-form-input id="filter-input" v-model="filter" type="search"
                                            placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">{{ $t('Clear')
                                                }}</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-button block variant="primary" @click.prevent="chooseBarang">
                                    {{ $t('Choose Items')
                                    }}</b-button>
                                <modal-barang @submit="onChooseBarang"></modal-barang>
                            </b-col>
                            <!-- <b-col md="12" sm="12">
                                <h4>{{ $t('Additional Items') }}<span
                                        v-if="selectedBarangs && selectedBarangs.length > 0">
                                        ({{ selectedBarangs.length
                                        }})</span></h4>
                            </b-col> -->
                            <b-col md="12">
                                <b-table small responsive :fields="fieldstambahan" :items="selectedBarangs"
                                    :filter="filter" :filter-included-fields="filterOn">
                                    <template #table-colgroup="{ fields }">
                                        <col v-for="field in fields" :key="field.key"
                                            :style="{ width: field.key == 'no' ? '20px' : '' }" />
                                    </template>
                                    <template #cell(no)="{ index }">
                                        {{ 1 + index }}
                                    </template>
                                    <template #cell(harga_jual)="{ item }">
                                        <!-- <span v-if="item.harga_jual"> -->
                                        <b-form-input type="text" class="w-70" v-model="item.harga_jual"></b-form-input>
                                        <!-- {{ item.harga_jual[0] ? formatRupiah(item.harga_jual[0].harga) : 0 }} -->
                                        <!-- // </span> -->
                                        <!-- <i v-else class="text-danger">Data tidak ada.</i> -->
                                    </template>
                                    <template #cell(kode)="{ item }">
                                        <span v-if="item">
                                            {{ item.kode }}<br>
                                            <small>{{ item.varian_item[0].no_batch }}</small>
                                        </span>
                                        <i v-else class="text-danger">Data tidak ada.</i>
                                    </template>
                                    <template #cell(kategori)="{ item }">
                                        {{ item.kategori ? item.kategori.kategori : '-' }}
                                    </template>
                                    <template #cell(no_container)="{ item }">
                                        <b-form-input v-model="item.no_container"
                                            placeholder="Ex: MRT123213"></b-form-input>
                                    </template>
                                    <template #cell(nama)="{ item }">
                                        <span v-if="item">
                                            {{ item.varian_item[0].varian }}<br>
                                            <small>{{ item.kategori.kategori }}</small> /
                                            <small>{{ item.nama }}</small>
                                        </span>
                                        <i v-else class="text-danger">Barang tidak ada.</i>
                                    </template>
                                    <template #cell(no_seal)="{ item }">
                                        <b-form-input v-model="item.no_seal" placeholder="Ex: GR123"></b-form-input>
                                    </template>
                                    <template #cell(harga_beli)="{ item }">
                                        <div>{{ formatRupiah(item.harga_beli) }}</div>
                                        <strong class="text-danger" v-if="item.diskon && item.diskon > 0"> diskon {{
                                            formatRupiah(item.diskon)
                                        }}
                                        </strong>
                                    </template>
                                    <template #cell(diskon)="{ item }">
                                        {{ formatRupiah(item.diskon) }}
                                    </template>
                                    <template #cell(qty)="{ item }">
                                        <b-input-group size="lg" :append="item.satuan ?
                                            item.satuan.satuan :
                                            null">
                                            <b-form-input type="number" class="w-70" v-model="item.qty"></b-form-input>
                                        </b-input-group>
                                    </template>
                                    <!-- /<small>{{
                                        item.satuan ?
                                            item.satuan.satuan :
                                            "" }}</small> </template> -->
                                    <template #cell(total)="{ item }">
                                        {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                                    </template>
                                    <template #cell(action)="{ item, index }">
                                        <feather-icon icon="MinusCircleIcon" size="24"
                                            class="text-danger cursor-pointer"
                                            @click="removeBarang(item, index)"></feather-icon>
                                    </template>
                                    <template #cell(keterangan)="{ item }">
                                        <b-form-input v-model="item.keterangan" />
                                    </template>
                                </b-table>

                            </b-col>
                            <b-col md="6" sm="12">

                            </b-col>
                        </b-row>
                    </b-card-actions>
                </b-overlay>
            </b-tab>
            <b-tab title="Informasi Pengiriman">

                <b-overlay :show="loadingInfoBarang">
                    <b-card-actions :no-actions="true">
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-alert variant="primary" show>
                                    <h4 class="alert-heading">
                                        Pengiriman
                                    </h4>
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4" sm="6">
                                <b-form-group label="Kurir">
                                    <validation-provider #default="{ errors }" name="kurir"
                                        :rules="{ required: false }">
                                        <b-input-group append="">
                                            <b-form-input type="text" v-model="markers[0].kurir" placeholder="1000" />
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="4" sm="6">
                                <b-form-group label="AWB / Nomor Tracking">
                                    <validation-provider #default="{ errors }" name="awb" :rules="{ required: false }">
                                        <b-input-group append="">
                                            <b-form-input type="text" v-model="markers[0].awb" placeholder="100" />
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <!-- </b-row>
                <b-row> -->
                            <b-col md="4" sm="6">
                                <b-form-group label="Label Pengiriman (PDF)">
                                    <validation-provider #default="{ errors }" name="file" :rules="{ required: false }">
                                        <b-input-group append="">
                                            <b-form-file v-model="markers[0].file" placeholder="10" />
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="d-flex justify-content-end">
                            <b-button @click.prevent="submit" variant="primary">
                                {{ label }}
                            </b-button>
                        </div>

                    </b-card-actions>
                </b-overlay>
            </b-tab>
        </b-tabs>
    </main>
</template>
<script>
import ModalBarang from "./ModalBarang.vue";
import { latLngBounds, icon } from 'leaflet';
import {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers
} from 'vue2-leaflet';
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
    BInputGroup,
    BOverlay,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BPagination,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BCard,
    BTable,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BAlert,
    BTableSimple,
    BTh, BTd, BTr, BThead, BTfoot, BTbody, BFormRadio,
} from "bootstrap-vue";
import { getSubject } from "../../../../libs/acl/utils";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
    ...required,
    message: "This field is required",
});

const markers1 = [
    {
        position: { lng: -1.219482, lat: 47.41322 },
        visible: true,
        draggable: true,
    },
    { position: { lng: -1.571045, lat: 47.457809 } },
    { position: { lng: -1.560059, lat: 47.739323 } },
    { position: { lng: -0.922852, lat: 47.886881 } },
    { position: { lng: -0.769043, lat: 48.231991 } },
    { position: { lng: 0.395508, lat: 48.268569 } },
    { position: { lng: 0.604248, lat: 48.026672 } },
    { position: { lng: 1.2854, lat: 47.982568 } },
    { position: { lng: 1.318359, lat: 47.894248 } },
    { position: { lng: 1.373291, lat: 47.879513 } },
    { position: { lng: 1.384277, lat: 47.798397 } },
    { position: { lng: 1.329346, lat: 47.754098 } },
    { position: { lng: 1.329346, lat: 47.680183 } },
    { position: { lng: 0.999756, lat: 47.635784 } },
    { position: { lng: 0.86792, lat: 47.820532 } },
    { position: { lng: 0.571289, lat: 47.820532 } },
    { position: { lng: 0.439453, lat: 47.717154 } },
    { position: { lng: 0.439453, lat: 47.61357 } },
    { position: { lng: -0.571289, lat: 47.487513 } },
    { position: { lng: -0.615234, lat: 47.680183 } },
    { position: { lng: -0.812988, lat: 47.724545 } },
    { position: { lng: -1.054688, lat: 47.680183 } },
    { position: { lng: -1.219482, lat: 47.41322 } },
];

const poly1 = [
    { lng: -1.219482, lat: 47.41322 },
    { lng: -1.571045, lat: 47.457809 },
    { lng: -1.560059, lat: 47.739323 },
    { lng: -0.922852, lat: 47.886881 },
    { lng: -0.769043, lat: 48.231991 },
    { lng: 0.395508, lat: 48.268569 },
    { lng: 0.604248, lat: 48.026672 },
    { lng: 1.2854, lat: 47.982568 },
    { lng: 1.318359, lat: 47.894248 },
    { lng: 1.373291, lat: 47.879513 },
    { lng: 1.384277, lat: 47.798397 },
    { lng: 1.329346, lat: 47.754098 },
    { lng: 1.329346, lat: 47.680183 },
    { lng: 0.999756, lat: 47.635784 },
    { lng: 0.86792, lat: 47.820532 },
    { lng: 0.571289, lat: 47.820532 },
    { lng: 0.439453, lat: 47.717154 },
    { lng: 0.439453, lat: 47.61357 },
    { lng: -0.571289, lat: 47.487513 },
    { lng: -0.615234, lat: 47.680183 },
    { lng: -0.812988, lat: 47.724545 },
    { lng: -1.054688, lat: 47.680183 },
    { lng: -1.219482, lat: 47.41322 },
];

const tileProviders = [
    {
        name: 'OpenStreetMap',
        visible: true,
        attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    {
        name: 'OpenTopoMap',
        visible: false,
        url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
];

export default {
    components: {
        ModalBarang,
        LMap,
        LTileLayer,
        LMarker,
        LPolyline,
        LLayerGroup,
        LTooltip,
        LPopup,
        LControlZoom,
        LControlAttribution,
        LControlScale,
        LControlLayers,
        BAlert,
        BInputGroup,
        BTableSimple,
        BTh, BTd, BTr, BThead, BTfoot, BTbody,
        BOverlay,
        Cleave,
        BTabs,
        BTab,
        BTable,
        BCard,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        BCardActions,
        BContainer,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormSelect,
        BFormInput,
        BFormFile,
        BFormTextarea,
        VBTooltip,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
        BModal,
        BPagination,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormCheckbox,
        BForm, BFormRadio,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data: () => ({

        dismissSecs: 10,
        dismissCountDown: 0,
        date: '',
        cashier: '',
        customer: '',
        barcode: '',
        qty: 1,
        items: [],
        fields: ['barcode', 'product', 'price', 'qty', 'discount', 'total', 'actions'],
        subTotal: 0,
        discount: 0,
        grandTotal: 0,
        cash: 0,
        change: 0,
        customers: ['Umum', 'VIP'],
        //Produk
        filter: null,
        filterOn: [],
        selectedBarangs: [],
        fieldstambahan: [
            // { key: "no_container", label: "No. Container" },
            // { key: "no_seal", label: "No. Seal" },
            // { key: "kategori", label: "kategori" },
            { key: "kode", label: "Barcode" },
            { key: "nama", label: "Nama Barang" },
            { key: "harga_jual", label: "Harga Jual" },
            { key: "diskon", label: "Diskon" },
            { key: "qty", label: "Qty" },
            { key: "keterangan", label: "Keterangan" },
            { key: "action", label: "" },
        ],
        label: 'Simpan',
        loadingInfoBarang: false,
        //PEmbeli
        form: {
            nama_pembeli: null,
            telp_pembeli: null,
            email_pembeli: null,
            catatan_pembeli: null,
            nama_penerima: null,
            telp_penerima: null,
            provinsi_id: null,
            kab_kota_id: null,
            kecamatan_id: null,
            desa_id: null,
            alamat: null,
            item: [
                {
                    barang_id: null,
                    qty: null,
                    harga_jual: null,
                    diskon: null,
                }
            ],
            kurir: null,
            awb: null,
            file: null,

        },
        optprovinsi: [],
        optkabko: [],
        optkec: [],
        optkel: [],
        mode: 0,
        text: 'Hier wird deine Latitude und Longitude angezeigt.',
        lat: '',
        lng: '',
        center: [51.505, -0.09],
        opacity: 0.6,
        token: 'your token if using mapbox',
        mapOptions: {
            zoomControl: false,
            attributionControl: false,
            zoomSnap: true,
        },
        zoom: 15,
        minZoom: 1,
        maxZoom: 20,
        zoomPosition: 'topleft',
        attributionPosition: 'bottomright',
        layersPosition: 'topright',
        attributionPrefix: 'Vue2Leaflet',
        imperial: false,
        Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
        tileProviders: tileProviders,
        markers: [
            {
                // id: 'm1',
                position: { lat: 51.505, lng: -0.09 },
                tooltip: 'Posisi kamu disini',
                draggable: true,
                visible: true,
                icon: icon({
                    iconUrl: "/assets/gps.png",
                    iconSize: [32, 37],
                    iconAnchor: [16, 37]
                }),
                // label_alamat: null, // OPTIONAL
                // provinsi_id: null,
                // latitude: null,  // OPTIONAL
                // longitude: null,  // OPTIONAL
                // kab_kota_id: null,
                // kecamatan_id: null,
                // desa_id: null, // OPTIONAL
                // alamat: null,
                // nomor_kontak: null,
                // nama_kontak: null,
                // jenis_alamat: 'toko', // PILIHAN utama toko retur
                // catatan: null, // OPTIONAL

                nama_pembeli: null,
                telp_pembeli: null,
                email_pembeli: null,
                catatan_pembeli: null,
                nama_penerima: null,
                telp_penerima: null,
                provinsi_id: null,
                kab_kota_id: null,
                kecamatan_id: null,
                desa_id: null,
                alamat: null,
                item: [
                    {
                        barang_id: null,
                        qty: null,
                        harga_jual: null,
                        diskon: null,
                    }
                ],
                kurir: null,
                awb: null,
                label: null,
            },
            // {
            //   id: 'm2',
            //   position: { lat: 51.8905, lng: -0.09 },
            //   tooltip: 'tooltip for marker2',
            //   draggable: true,
            //   visible: false,
            //   icon: icon({
            //     iconUrl: "/assets/gps.png",
            //     iconSize: [32, 37],
            //     iconAnchor: [16, 37]
            //   }),
            // },
            // {
            //   id: 'm3',
            //   position: { lat: 51.005, lng: -0.09 },
            //   tooltip: 'tooltip for marker3',
            //   draggable: true,
            //   visible: true,
            //   icon: icon({
            //     iconUrl: "/assets/gps.png",
            //     iconSize: [32, 37],
            //     iconAnchor: [16, 37]
            //   }),
            // },
            // {
            //   id: 'm4',
            //   position: { lat: 50.7605, lng: -0.09 },
            //   tooltip: 'tooltip for marker4',
            //   draggable: true,
            //   visible: false,
            //   icon: icon({
            //     iconUrl: "/assets/gps.png",
            //     iconSize: [32, 37],
            //     iconAnchor: [16, 37]
            //   }),
            // },
        ],
        polylines: [
            {
                id: 'p1',
                points: [
                    { lat: 37.772, lng: -122.214 },
                    { lat: 21.291, lng: -157.821 },
                    { lat: -18.142, lng: -181.569 },
                    { lat: -27.467, lng: -206.973 },
                ],
                visible: true,
            },
            {
                id: 'p2',
                points: [
                    [-73.91, 40.78],
                    [-87.62, 41.83],
                    [-96.72, 32.76],
                ],
                visible: true,
            },
        ],
        stuff: [
            {
                id: 's1',
                markers: markers1,
                polyline: { points: poly1, visible: true },
                visible: true,
                markersVisible: true,
            },
        ],
        bounds: latLngBounds(
            { lat: 51.476483373501964, lng: -0.14668464136775586 },
            { lat: 51.52948330894063, lng: -0.019140238291583955 }
        ),
        loadingmap: false,
    }),

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
        },
    },
    watch: {
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        kembalian() {
            return this.change = this.cash - this.grandTotal;
        },
        addItem() {
            const existingItemIndex = this.items.findIndex(item => item.barcode === this.barcode);

            // If the item already exists, increase the quantity
            if (existingItemIndex !== -1) {
                this.items[existingItemIndex].qty += parseInt(this.qty);
                this.items[existingItemIndex].total = this.items[existingItemIndex].qty * this.items[existingItemIndex].price;
            } else {
                // Otherwise, add a new item to the list
                this.getDataBarang()
                // const newItem = {
                //     barcode: this.selectedBarangs.bar,
                //     product: this.getProductName(this.barcode), // Assuming you have a method to get the product name
                //     price: this.getProductPrice(this.barcode), // Assuming you have a method to get the product price
                //     qty: parseInt(this.qty),
                //     discount: 0,
                //     total: this.getProductPrice(this.barcode) * parseInt(this.qty),
                // };
                // this.items.push(newItem);
            }

            // Reset input fields after adding/updating
            this.barcode = '';
            this.qty = 1;

            // Recalculate totals
            this.calculateTotals();
        },

        // Helper methods to get product name and price based on barcode (dummy examples)
        getProductName(barcode) {
            const products = {
                'MA001': 'Pop Mie',
                'MA002': 'Indomie Goreng',
                'MA003': 'Indomie Soto Kuah'
            };
            return products[barcode] || 'Unknown';
        },

        getProductPrice(barcode) {
            const prices = {
                'MA001': 5000,
                'MA002': 2500,
                'MA003': 2500
            };
            return prices[barcode] || 0;
        },

        calculateTotals() {
            this.subTotal = this.items.reduce((sum, item) => sum + item.total, 0);
            this.grandTotal = this.subTotal - this.discount;
        },
        updateItem(index) {
            // Logic to update an item
        },
        deleteItem(index) {
            // Logic to delete an item
        },
        processPayment() {
            // Logic to process the payment
        },
        cancel() {
            // Logic to cancel the transaction
        },
        onChooseBarang(selectedBarangs) {
            selectedBarangs.map((barang) => {
                barang.no_container = null;
                barang.no_seal = null;
                this.selectedBarangs.push(barang);
            });
            this.$bvModal.hide("modal-barang");
        },
        chooseBarang() {
            this.$bvModal.show("modal-barang");
        },
        alert(item) {
            alert('this is ' + JSON.stringify(item));
        },
        async getDataBarang() {
            // const jenis = this.isOwner ? 1 : 2
            // const payload = {
            //   order: "desc",
            //   start: 1,
            //   length: 10,
            // };
            // this.loading = true
            //   const perPage = parseInt(this.perPage);
            //   const currentPage = (parseInt(this.currentPage) - 1) * perPage;
            const payload = {
                kode_barang: this.barcode,
                // order: this.sortBy ? this.sortBy : null,
                // start: currentPage,
                // length: this.perPage,
                // kode: this.barcode,
                // show_stock: 1,
                // kategori_id: this.category,
            };
            // if (this.isGM) payload.jenis = 2;
            this.$store
                .dispatch("tokopenyimpanan/getData", payload)
                .then((response) => {
                    let hem = response
                    // let items = JSON.parse(JSON.stringify(this.$store.state.tokopenyimpanan.datas));
                    // let items_total = this.$store.state.tokopenyimpanan.totals;
                    // this.items = items
                    // this.totalRows = items_total
                    // this.items.map((barang) => {
                    //     barang.qty = 0;
                    //     barang.diskon = 0;
                    //     barang.harga_beli = 0;
                    //     barang.isChangePrice = false;
                    //     barang.harga_dasar_baru = 0; // bila ingin merubha harga dasar
                    // });
                    // 2055729886952
                    // 2010238659084
                    // 2014065678096
                    console.log('aji', hem)
                    if (hem.length == 1) {
                        if (hem[0].barang.harga_jual == [] || hem[0].barang.harga_jual == null || hem[0].barang.harga_jual.length == 0) {
                            this.displayDanger({
                                message: 'Harga Jual belum ditentukan',
                                position: 'top-right',
                            })
                            return false
                        } else if (hem[0].jumlah <= 0) {
                            this.displayDanger({
                                message: 'Stok habis, harap update stok melalui menu "Inbound"',
                                position: 'top-right',
                            })
                            return false
                        } else {
                            const newItem = {
                                penyimpanan_id: hem[0].id,
                                barang_id: hem[0].barang.id,
                                barcode: hem[0].barang.kode,
                                product: hem[0].barang.nama, // Assuming you have a method to get the product name
                                product_varian: hem[0].barang.varian, // Assuming you have a method to get the product name
                                product_kategori: hem[0].barang.kategori.kategori, // Assuming you have a method to get the product name
                                price: hem[0].barang.harga_jual[0].harga, // Assuming you have a method to get the product price
                                qty: parseInt(this.qty),
                                discount: 0,
                                total: hem[0].barang.harga_jual[0].harga * parseInt(this.qty),
                            };
                            this.items.push(newItem);
                        }
                    } else {
                        this.showAlert()
                        this.displayDanger({
                            message: 'Product Not Found',
                            position: 'top-right',
                        })
                    }

                    // this.loading = false
                })
        },
        // icon({
        //     iconUrl= "/assets/gps.png",
        //     iconSize= [32, 37],
        //     iconAnchor= [16, 37]
        //   }),
        addMarker: function () {
            const newMarker = {
                position: { lat: this.lat, lng: this.lng },
                draggable: true,
                visible: true,
            };
            this.markers.push(newMarker);
        },
        removeMarker: function (index) {
            this.markers.splice(index, 1);
        },
        fitPolyline: function () {
            const bounds = latLngBounds(markers1.map(o => o.position));
            this.bounds = bounds;
        },
        getLocation() {
            this.loadingmap = true
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(this.showPosition);
            } else {
                text = "Geolocation is not supported by this browser.";
            }
            // this.showPosition()
        },
        showPosition(position) {
            this.lat = position.coords.latitude,
                this.lng = position.coords.longitude,
                this.text = "deine Location befindet sich in: Latitude:" + this.lat + " und Longitude: " + this.lng;
            this.markers[0].position.lat = this.lat
            this.markers[0].position.lng = this.lng
            this.center = [this.lat, this.lng]
            // console.log('AJI', this.center)
            this.mode = 1
            this.displayInfo({
                message: 'Lokasi sudah terbarui'
            })
            this.loadingmap = false
        },
        async getProvinsi() {
            let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
            provinsi = JSON.parse(JSON.stringify(provinsi));
            let newprovinsi = [];
            provinsi.map((item) => {
                newprovinsi.push({
                    text: item.name,
                    value: item.id,
                });
            });
            // this.id_provinsi = newprovinsi;
            this.optprovinsi = newprovinsi;
            // });
        },
        async getkabkota() {
            const params = {
                id_provinsi: this.markers[0].provinsi_id ? this.markers[0].provinsi_id : null
            }
            let kabko = await this.$store.dispatch("wilayah/getkabko", params);
            kabko = JSON.parse(JSON.stringify(kabko));
            let newkabko = [];
            kabko.map((item) => {
                newkabko.push({
                    text: item.name,
                    value: item.id,
                });
            });
            this.optkabko = newkabko;
        },
        async getkecamatan() {
            const params = {
                id_kab_kota: this.markers[0].kab_kota_id ? this.markers[0].kab_kota_id : null
            }
            let kec = await this.$store.dispatch("wilayah/getkecamatan", params);
            kec = JSON.parse(JSON.stringify(kec));
            let newkec = [];
            kec.map((item) => {
                newkec.push({
                    text: item.name,
                    value: item.id,
                });
            });
            this.optkec = newkec;
        },
        async getkelurahan() {
            const params = {
                id_kecamatan: this.markers[0].kecamatan_id
            }
            let kel = await this.$store.dispatch("wilayah/getkelurahan", params);
            kel = JSON.parse(JSON.stringify(kel));
            let newkel = [];
            kel.map((item) => {
                newkel.push({
                    text: item.name,
                    value: item.id,
                });
            });
            this.optkel = newkel;
        },

    },
    async mounted() {
        this.getLocation()
        this.getProvinsi()
    },
    async created() {
        // const action = this.$route.params.id ? "update" : "create";

        // this.checkPermission(action);

        // this.getTypeKonsumen();

        // this.getKategori();

        // this.getJenisPenyakit();

        // this.getKelompok();

        // this.getLayananEkspedisi();

        // this.getSatuan();

        // // this.getStatus();

        // if (this.$route.params.id) {
        //     let barang = this.$store.getters["barang/getBarang"](this.$route.params.id);
        //     if (!barang) {
        //         barang = await this.$store.dispatch("barang/getDataById", this.$route.params.id);
        //     }

        //     // let ter = JSON.parse(JSON.stringify(barang));
        //     //     ter.map((item) => {
        //     //         item.value = item.id;
        //     //         item.text = item.nama + ' - ' + item.satuan.satuan;
        //     //     });
        //     this.form2 = {
        //         id_barang: barang.id,
        //     };
        //     this.form = {
        //         nama: barang.nama,
        //         jenis: barang.jenis,
        //         id_kategori: barang.kategori ? barang.kategori.id : null,
        //         id_satuan: barang.satuan ? barang.satuan.id : null,
        //         harga_dasar: barang.harga_dasar,
        //         variants: [
        //             {
        //                 kode: barang.kode,
        //                 varian: barang.varian,
        //             },
        //         ],
        //     };
        // }
    },
};
</script>