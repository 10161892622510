var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Informasi Pembeli"
    }
  }, [[_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kasir"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.cashier,
      callback: function ($$v) {
        _vm.cashier = $$v;
      },
      expression: "cashier"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Customer"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.customers
    },
    model: {
      value: _vm.customer,
      callback: function ($$v) {
        _vm.customer = $$v;
      },
      expression: "customer"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Barcode"
    }
  }, [_c('b-form-input', {
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.addItem();
      }
    },
    model: {
      value: _vm.barcode,
      callback: function ($$v) {
        _vm.barcode = $$v;
      },
      expression: "barcode"
    }
  })], 1)], 1), _c('b-col', [_c('b-alert', {
    attrs: {
      "show": _vm.dismissCountDown,
      "dismissible": "",
      "variant": "danger"
    },
    on: {
      "dismissed": function ($event) {
        _vm.dismissCountDown = 0;
      },
      "dismiss-count-down": _vm.countDownChanged
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('Product not found')))])])])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Qty"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.qty,
      callback: function ($$v) {
        _vm.qty = $$v;
      },
      expression: "qty"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-end",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addItem
    }
  }, [_vm._v("Add")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h3', [_vm._v("Invoice")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h1', [_vm._v(_vm._s(_vm.grandTotal))])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "items": _vm.items,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(product)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.product_varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.product_kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.product))])];
      }
    }, {
      key: "cell(actions)",
      fn: function (data) {
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.updateItem(data.index);
            }
          }
        }, [_vm._v("Update")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItem(data.index);
            }
          }
        }, [_vm._v("Delete")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', [_c('b-row', {}, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sub Total"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.subTotal,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Discount"
    }
  }, [_c('b-form-input', {
    on: {
      "input": function ($event) {
        return _vm.calculateTotals();
      }
    },
    model: {
      value: _vm.discount,
      callback: function ($$v) {
        _vm.discount = $$v;
      },
      expression: "discount"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Grand Total"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.grandTotal,
      "readonly": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cash"
    }
  }, [_c('b-form-input', {
    on: {
      "input": function ($event) {
        return _vm.kembalian();
      }
    },
    model: {
      value: _vm.cash,
      callback: function ($$v) {
        _vm.cash = $$v;
      },
      expression: "cash"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Change"
    }
  }, [_c('b-form-input', {
    attrs: {
      "readonly": ""
    },
    model: {
      value: _vm.change,
      callback: function ($$v) {
        _vm.change = $$v;
      },
      expression: "change"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.processPayment
    }
  }, [_vm._v("Process Payment")]), _c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Note"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.cash,
      callback: function ($$v) {
        _vm.cash = $$v;
      },
      expression: "cash"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]], 2), _c('b-tab', {
    attrs: {
      "title": "Informasi Pembeli"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Pembeli ")])])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Pembeli",
      "label-for": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Ex: Sarung Tangan Medis"
    },
    model: {
      value: _vm.markers[0].nama_pembeli,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "nama_pembeli", $$v);
      },
      expression: "markers[0].nama_pembeli"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telp Pembeli",
      "label-for": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Ex: 08222222222"
    },
    model: {
      value: _vm.markers[0].telp_pembeli,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "telp_pembeli", $$v);
      },
      expression: "markers[0].telp_pembeli"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail Pembeli",
      "label-for": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "email",
      "placeholder": "official@heykama.com"
    },
    model: {
      value: _vm.markers[0].email_pembeli,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "email_pembeli", $$v);
      },
      expression: "markers[0].email_pembeli"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Catatan Pembeli"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.markers[0].catatan_pembeli,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "catatan_pembeli", $$v);
      },
      expression: "markers[0].catatan_pembeli"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Informasi Penerima"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Penerima ")])])], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama_kontak"
    }
  }, [_vm._v("Nama Lengkap")]), _c('b-form-input', {
    attrs: {
      "id": "nama_kontak",
      "type": "text",
      "placeholder": "Nama Lengkap"
    },
    model: {
      value: _vm.markers[0].nama_kontak,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "nama_kontak", $$v);
      },
      expression: "markers[0].nama_kontak"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nomor_kontak"
    }
  }, [_vm._v("No. Telepon (Aktif)")]), _c('b-form-input', {
    attrs: {
      "id": "nomor_kontak",
      "type": "text",
      "placeholder": "Nomor Telepon"
    },
    model: {
      value: _vm.markers[0].nomor_kontak,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "nomor_kontak", $$v);
      },
      expression: "markers[0].nomor_kontak"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Provinsi",
      "label-for": "v-provinsi"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optprovinsi
    },
    on: {
      "input": function ($event) {
        return _vm.getkabkota();
      }
    },
    model: {
      value: _vm.markers[0].provinsi_id,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "provinsi_id", $$v);
      },
      expression: "markers[0].provinsi_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kabupaten/Kota",
      "label-for": "v-kabko"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkabko
    },
    on: {
      "input": function ($event) {
        return _vm.getkecamatan();
      }
    },
    model: {
      value: _vm.markers[0].kab_kota_id,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "kab_kota_id", $$v);
      },
      expression: "markers[0].kab_kota_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kecamatan",
      "label-for": "v-kecamatan"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkec
    },
    on: {
      "input": function ($event) {
        return _vm.getkelurahan();
      }
    },
    model: {
      value: _vm.markers[0].kecamatan_id,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "kecamatan_id", $$v);
      },
      expression: "markers[0].kecamatan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kelurahan/Desa",
      "label-for": "v-kel"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkel
    },
    model: {
      value: _vm.markers[0].desa_id,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "desa_id", $$v);
      },
      expression: "markers[0].desa_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v("Alamat")]), _c('b-form-textarea', {
    attrs: {
      "id": "alamat",
      "placeholder": "Nama Jalan, Gang, Gedung, No Rumah"
    },
    model: {
      value: _vm.markers[0].alamat,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "alamat", $$v);
      },
      expression: "markers[0].alamat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "reward"
    }
  }, [_vm._v("Catatan")]), _c('b-form-textarea', {
    attrs: {
      "id": "deskripsi",
      "placeholder": "Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)"
    },
    model: {
      value: _vm.markers[0].catatan,
      callback: function ($$v) {
        _vm.$set(_vm.markers[0], "catatan", $$v);
      },
      expression: "markers[0].catatan"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Informasi Produk"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Produk ")])])], 1), _c('b-col', {
    attrs: {
      "md": "8",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Choose Items')))]), _c('modal-barang', {
    on: {
      "submit": _vm.onChooseBarang
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "fields": _vm.fieldstambahan,
      "items": _vm.selectedBarangs,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref2) {
        var fields = _ref2.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('b-form-input', {
          staticClass: "w-70",
          attrs: {
            "type": "text"
          },
          model: {
            value: item.harga_jual,
            callback: function ($$v) {
              _vm.$set(item, "harga_jual", $$v);
            },
            expression: "item.harga_jual"
          }
        })];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.kode)), _c('br'), _c('small', [_vm._v(_vm._s(item.varian_item[0].no_batch))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Data tidak ada.")])];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.kategori ? item.kategori.kategori : '-') + " ")];
      }
    }, {
      key: "cell(no_container)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: MRT123213"
          },
          model: {
            value: item.no_container,
            callback: function ($$v) {
              _vm.$set(item, "no_container", $$v);
            },
            expression: "item.no_container"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.varian_item[0].varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(no_seal)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: GR123"
          },
          model: {
            value: item.no_seal,
            callback: function ($$v) {
              _vm.$set(item, "no_seal", $$v);
            },
            expression: "item.no_seal"
          }
        })];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('b-input-group', {
          attrs: {
            "size": "lg",
            "append": item.satuan ? item.satuan.satuan : null
          }
        }, [_c('b-form-input', {
          staticClass: "w-70",
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        })], 1)];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref14) {
        var item = _ref14.item,
          index = _ref14.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Informasi Pengiriman"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Pengiriman ")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kurir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kurir",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref16) {
        var errors = _ref16.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "1000"
          },
          model: {
            value: _vm.markers[0].kurir,
            callback: function ($$v) {
              _vm.$set(_vm.markers[0], "kurir", $$v);
            },
            expression: "markers[0].kurir"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "AWB / Nomor Tracking"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "awb",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var errors = _ref17.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "100"
          },
          model: {
            value: _vm.markers[0].awb,
            callback: function ($$v) {
              _vm.$set(_vm.markers[0], "awb", $$v);
            },
            expression: "markers[0].awb"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label Pengiriman (PDF)"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "file",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var errors = _ref18.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('b-form-file', {
          attrs: {
            "placeholder": "10"
          },
          model: {
            value: _vm.markers[0].file,
            callback: function ($$v) {
              _vm.$set(_vm.markers[0], "file", $$v);
            },
            expression: "markers[0].file"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }